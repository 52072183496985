<template>
  <div>
    <v-select
      :label='$t("model.prop.schoolYear")'
      class="white"
      :items="filterYears"
      :item-text="classrooms"
      item-value="id"
      v-model="id"
      :loading="api.isLoading"
      placeholder="School Year"
      :rules="rules"
    ></v-select>
  </div>
</template>

<script>
import { mapState } from "vuex";
import datasetSchoolYear from "@/datasets/schoolYear";
export default {
  props: ["callbackSelect", "callbackError"],
  data: () => ({
    rules: [(v) => !!v || "Classroom is required"],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    allYears: [],
    filterYears: [],
    id: null,
    item: null,
  }),
  computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
  },
  created() {
    //BOC:[api]
    this.api.method = "get";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      this.filterYear();
    };
    //EOC
  },
  watch: {
    id: function (val) {
      this.id = val;
      this.item = this.$_.find(this.filterYears, { id: this.id });
      this.callbackSelect(this.item);
    },
  },
  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.backend +
        "/v1/school/" +
        this.school.id +
        "/classroom";
      this.$api.fetch(this.api);
    },
    filterYear() {
      var years = [];
      for (var n = 0, m = this.data.length; n < m; n++) {
        if (years.length == 0) {
          years.push(this.data[n].schoolYear);
        } else {
          if (!years.includes(this.data[n].schoolYear)) {
            years.push(this.data[n].schoolYear);
          }
        }
      }
      for (var x = 0, y = years.length; x < y; x++) {
        for (var a = 0, b = this.allYears.length; a < b; a++) {
          if (years[x] == this.allYears[a].id) {
            this.filterYears.push(this.allYears[a]);
          }
        }
      }
      this.filterYears.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.item = this.filterYears[0];
      this.id = this.filterYears[0].id;
    },
    classrooms: (item) => item.name,
  },
  beforeMount() {
    this.allYears = datasetSchoolYear;
    this.fetch();
  },
};
</script>
